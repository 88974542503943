import React from "react"

import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

export default () => (
  <Layout>
    <SEO title="Quote" meta={["security", "quote"]} />
    <section>
      <h2>Request a Quote</h2>
      <form>
        <input name="givenName" type="text" placeholder="First Name" />
        <input name="surname" type="text" placeholder="Last Name" />
        <input name="number" type="number" placeholder="Phone Number" />
        <input name="address" type="text" placeholder="Address" />
      </form>
    </section>
  </Layout>
)
